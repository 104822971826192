<template>
  <div>

    <b-modal :visible="modals.recoveryPassword" :title="$t('recoveryPassword.title')"
      content-class="card form-rounded-inputs card-ecart" centered header-class="card-header" @hidden="fnResetUserInformation()"
      cancel-variant="danger" size="md" hide-header-close>

      <div>
        <p>
          <strong class="f-w-600" v-text=" $t('recoveryPassword.message') "></strong>
        </p>
        <hr>
        <b-alert variant="danger" dismissible fade :show="showAlert">
          <i class=" fa fa-warning "></i>
          <span v-text="$t('recoveryPassword.alertSeller')"> </span>
        </b-alert>
        <v-observer tag="form" ref="formEmailRecovery" @submit.prevent="fnValidateEmail()" v-if="!emailSended">
          <v-validation rules="required|email" v-slot="{ errors }" :name=" $t('general.form.email') ">
            <div class="form-group">
              <label for="" v-text=" $t('general.form.email') "></label>
              <div class="input-group ">
                <input type="email" min="1" placeholder="example@example.com" class="form-control" v-model=" email "
                  :class=" { 'is-invalid':errors.length > 0 } " @focus=" showAlert = false ">
                <div class="input-group-append">
                  <span class="input-group-text ">
                    <strong>
                      <i class=" fa fa-envelope "></i>
                    </strong>
                  </span>
                </div>
                <div class="invalid-feedback" v-if="errors.length > 0">
                  {{ errors[0] }}
                </div>
              </div>
            </div>
          </v-validation>
        </v-observer>
        <div v-else>
          <p class="text-success">
            <strong v-text="$t('toasted.info.recoveryPassword')"></strong>
          </p>

        </div>
      </div>

      <template v-slot:modal-footer>
        <p>
          <button @click=" fnResetUserInformation() " class="btn" v-text=" $t('general.button.close') "></button>
          <button class=" btn btn-success" :disabled="sendingData" @click="fnValidateEmail()" v-if="!emailSended">
            <span v-text="$t('general.button.send')  " v-if=" !sendingData "></span>
            <i class="fa fa-spinner fa-spin " v-else></i>
          </button>
        </p>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import {
    mapMutations,
    mapState
  } from 'vuex';
  export default {
    data() {
      return ({
        email: null,
        sendingData: false,
        emailSended: false,
        showAlert: false,
      })
    },
    computed: {
      ...mapState('session', ['modals']),
    },
    methods: {
      ...mapMutations('session', ['changeModal']),
      fnResetUserInformation() {
        this.changeModal({
          recoveryPassword: false
        });
        this.email = null;
        this.sendingData = false;
        this.emailSended = false;
        this.showAlert = false;
      },
      async fnValidateEmail() {
        if (await this.$refs['formEmailRecovery'].validate()) {
          this.sendingData = true;
          await axios.post('me/identify', {
            email: this.email
          }).then((response) => {
            this.$toasted.global.infoMessage(this.$t('toasted.info.recoveryPassword'));
            this.emailSended = true;
          }).catch((error) => {
            this.showAlert = true;
          });
          this.sendingData = false;
        }
      }
    },
    mounted() {}
  }
</script>

<style>

</style>