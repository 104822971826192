<template>

  <mobile-float-menu>
    <template v-slot:content>
      <div class="container-fluid">
        <div class="row ecart-login ">
          <div class="col-12 col-md-5 col-lg-4 d-flex flex-column  justify-content-center animated fadeInDown ">
            <Login class="p-2 p-lg-4 mb-5" mobileVerison />

            <div class="row justify-content-center mt-5 mb-3">
              <div class="col-auto px-1">
                <a href="https://www.instagram.com/seller.ecart" target="_blank" class="btn p-0">
                  <img src="@/assets/images/icons/icon_instagram.svg" class="img-30" alt="">
                </a>
              </div>
              <div class="col-auto px-1">
                <a href="https://www.facebook.com/ecart.marketplace" target="_blank" class="btn p-0">
                  <img src="@/assets/images/icons/icon_facebook.svg" class="img-30" alt="">
                </a>
              </div>
              <div class="col-auto px-1">
                <a href="https://www.youtube.com/channel/UCTXkvi3lazCDafPazt58Szw" target="_blank" class="btn p-0">
                  <img src="@/assets/images/icons/icon_youtube.svg" class="img-30" alt="">
                </a>
              </div>
            </div>

            <p class="text-center f-w-400 mb-4 ">
              <router-link to="/terms-&-conditions" v-text="$t('components.footer.links.terms')">
              </router-link>
              <span class="d-none d-md-inline"> / </span>
              <span class="text-white d-md-none"> / </span>
              <router-link to="/privacy-policy" v-text="$t('components.footer.links.policy')">
              </router-link>
              <br>
              <small>
                <span class="d-none d-md-inline">
                  © <span v-text=" new Date().getFullYear() "></span> Ecart by envia.com. All rights reserved.
                </span>
                <span class="text-white d-md-none">
                  © <span v-text=" new Date().getFullYear() "></span> Ecart by envia.com. All rights reserved.
                </span>
              </small>
            </p>

          </div>
          <div class="col-12 col-md-7 col-lg-8 d-none d-md-flex flex-column justify-content-between py-4 ecart-info">
            <div class="text-center">
              <h1>
                <strong v-text="$t('messages.welcomeEcart')"></strong>
              </h1>
              <h5 class="f-w-500" v-text="$t('messages.loginShipmentMessage')">
              </h5>
            </div>

            <div class="text-center">
              <h5 class="f-w-600" v-text="$t('messages.loginPublishesMessage')">
              </h5>
              <h5 v-text="$t('messages.loginManageProductsMessage')">
              </h5>
            </div>

          </div>
        </div>
        <LoginRecoveryPassword />
      </div>

    </template>
  </mobile-float-menu>
</template>

<script>
  import MobileFloatMenu from "@/components/MobileFloatMenu";
  import Login from "@/components/Login";
  import LoginRecoveryPassword from "./components/login-recovery-password-modal";

  export default {
    components: {
      Login,
      LoginRecoveryPassword,
      MobileFloatMenu,
    },
    mounted() {
      window.location.href = '/'; // TODO: Remove this line if is necessary
    }
  };
</script>

<style lang="scss">
  .ecart-login {
    min-height: 100vh;

    @media only screen and (max-width: 767px) {
      background-image: url('../../../assets/images/backgrounds/bg-2.png');
      background-size: cover;
    }
  }

  .ecart-info {
    color: white;
    background-image: url('../../../assets/images/ecart-login.png');
    background-position: center;
    background-repeat: no-repeat;
    background-color: black;
  }
</style>