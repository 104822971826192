<template>
  <div class="form-rounded-inputs">
    <div class="row mb-3 align-items-center">
      <div class="col-3">
      </div>
      <div class="col-6 text-center">
        <router-link to="/">
          <img src="@/assets/images/logo-ecart.svg" class=" img-100 d-none d-md-inline-block" alt="">
          <img src="@/assets/images/ecart-logo.png" class=" img-contain-150 d-md-none" alt="">
        </router-link>
      </div>
      <div class="col-3 text-right">
        <Languages variant='none p-0 d-none d-md-block'/>
      </div>
    </div>

    <b-alert variant="danger" fade :show="showAlert.password">
      <i class="fa fa-warning"></i>
      <span v-text="$t('login.alertPassword')"></span>
    </b-alert>
    <b-alert variant="danger" fade :show="showAlert.account">
      <i class="fa fa-warning"></i>
      <span v-text="$t('login.alertAccount')"></span>
    </b-alert>
    <b-alert variant="warning" fade :show="showAlert.session">
      <i class="fa fa-warning"></i>
      <span v-text="$t('login.alertSession')"></span>
    </b-alert>

    <v-observer class="form-dark-mobile" ref="myform" tag="form" @submit.prevent="fnValidate">
      <h4 class="text-center mb-4" v-if="!onlyLoign">
        <strong class="f-w-700" v-text="oauthApp || $t('login.login')"></strong>
      </h4>
      <div class="row">
        <div class="col-12">
          <v-validation rules="required" v-slot="{ errors }" :name="$t('login.email')">
            <div class="form-group">
              <label v-text="$t('login.email')" class="d-none">
              </label>
              <div class="input-group " :class="{ 'is-invalid': errors.length > 0 }">

                <div class="input-group-prepend d-md-none ">
                  <span class="input-group-text ">
                    <strong>
                      <i class=" fa fa-envelope "></i>
                    </strong>
                  </span>
                </div>
                <input v-model="user.email" class="form-control" type="email" name="Email"
                  :placeholder=" $t('login.email') "
                  :class="{ 'is-invalid': errors.length > 0 }" />
                <div class="input-group-append">
                  <span class="input-group-text ">
                    <strong>
                      <i class=" fa fa-envelope "></i>
                    </strong>
                  </span>
                </div>
                <div class="invalid-feedback" v-if="errors.length > 0">
                  {{ errors[0] }}
                </div>
              </div>
            </div>
          </v-validation>
          <v-validation rules="required" v-slot="{ errors }" :name="$t('login.password')">
            <div class="form-group">
              <label v-text="$t('login.password')" class="d-none">
              </label>
              <div class="input-group " :class="{ 'is-invalid': errors.length > 0 }">
                <div class="input-group-prepend d-md-none">
                  <span class="input-group-text ">
                    <strong>
                      <i class=" fa fa-key "></i>
                    </strong>
                  </span>
                </div>
                <input v-model="user.password" class="form-control" type="password" @focus="
                  showAlert.password = false;
                  showAlert.account = false;
                " :placeholder=" $t('login.password') " :class="{ 'is-invalid': errors.length > 0 }" />
                <div class="input-group-append">
                  <span class="input-group-text ">
                    <strong>
                      <i class=" fa fa-key "></i>
                    </strong>
                  </span>
                </div>
                <div class="invalid-feedback" v-if="errors.length > 0">
                  {{ errors[0] }}
                </div>
              </div>
            </div>
          </v-validation>
        </div>
        <div class="col-12 d-md-none ">
          <div class="row justify-content-center align-conent-center align-items-center mt-2">
            <div class="col-12 ml-auto">
              <button class="btn btn-success w-100" :disabled="loading.data">
                <span class=" " v-text="$t('login.loginBtn')" v-if="!loading.data"></span>
                <i class="fa fa-spinner fa-spin" v-else></i>
              </button>
            </div>
          </div>
        </div>
        <div class="col-auto mx-auto mt-3 mt-md-1" v-if="!onlyLoign">
          <button type="button" @click="changeModal( {recoveryPassword : true} )"
            class="btn btn-link text-success f-w-600 btn-sm px-0" v-text="$t('login.forgotPass')">
          </button>
        </div>
        <div class="d-none d-md-block col-12 " :class="onlyLoign ? '' : 'mt-5' ">
          <div class="row justify-content-center align-conent-center align-items-center mt-4">
            <div class="col-12" v-if="!onlyLoign">
              <p>
                <span v-text="$t('login.newUser')"> </span>
                <router-link to="signup">
                  <strong class="f-w-600" v-text="$t('login.signup')"></strong>
                </router-link>
              </p>
            </div>
            <div class="col-auto ml-auto">
              <button class="btn btn-primary" :disabled="loading.data">
                <span class=" " v-text="$t('login.loginBtn')" v-if="!loading.data"></span>
                <i class="fa fa-spinner fa-spin" v-else></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </v-observer>
  </div>
</template>

<script>
  import {
    mapState,
    mapMutations,
    mapActions,
  } from "vuex";
  import Languages from "@/components/Languages";

  export default {
    props: {
      onlyLoign: {
        type: Boolean,
        default: false,
      },
      mobileVerison: {
        type: Boolean,
        default: false,
      }
    },
    components: {
      Languages,
    },
    data() {
      return {
        user: {
          email: null,
          password: null
        },
        oauthApp: null,
      };
    },
    computed: {
      ...mapState("session", ["loading", "showAlert"])
    },
    methods: {
      ...mapMutations('session', ['changeModal']),
      ...mapActions("session", ["fnApiLogin"]),
      async fnValidate() {
        if (await this.$refs.myform.validate()) {
          this.fnApiLogin({
            email: this.user.email,
            password: this.user.password,
            query: this.$route.query,
          });
        }
      },
      oauthAppName() {
        if (typeof this.$route.query.app_id != 'undefined') {
          let {
            name
          } = this.$route.query;
          this.oauthApp = name;
        }
      }
    },
    mounted() {
      this.oauthAppName();
      if (this.$route.query.session_expired) {
        this.showAlert.session = true;
      } else {
        this.showAlert.session = false;
      }
    }
  };
</script>
<style lang="scss">
</style>